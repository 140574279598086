import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import Background from "../components/globals/Background"
import Info from "../components/Info"

const ThankYouPage = ({ data }) => (
  <Layout>
    <Background
      img={data.img.childImageSharp.fluid}
      styleClass="about-background"
    >
      <Info title="Thank You">
        <div className="row">
          <div className="col-8 col-sm-8">
            <p className="lead text-muted mb-5">
              Thank you for contacting me. I will get back to you as soon as
              possible.
            </p>
            <Link to="/about">
              <button className="btn text-uppercase btn-yellow">
                Learn more about me
              </button>
            </Link>
          </div>
        </div>
      </Info>
    </Background>
  </Layout>
)
export const query = graphql`
  {
    img: file(relativePath: { eq: "default-background.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
  }
`
export default ThankYouPage
